import {
	EVENT_ACTION_FLIGHTS_SUGGESTION_SELECT,
	EVENT_CATEGORY_FLIGHTS,
	trackEvent,
} from '../../../shared/analytics';
import { IFlightSearchSuggestion } from '../../search/domain/models/IFlightSearchSuggestion';

export function trackSuggestionSelect(
	selectedItem: IFlightSearchSuggestion | null,
) {
	if (!selectedItem) {
		return;
	}

	trackEvent({
		category: EVENT_CATEGORY_FLIGHTS,
		action: EVENT_ACTION_FLIGHTS_SUGGESTION_SELECT,
		label: selectedItem.value,
	});
}
