import {
	EVENT_ACTION_FLIGHTS_SHOW_MORE,
	EVENT_CATEGORY_FLIGHTS,
	EVENT_LABEL_FLIGHTS_SHOW_MORE_EARLIER,
	EVENT_LABEL_FLIGHTS_SHOW_MORE_LATER,
	trackEvent,
} from '../../../shared/analytics';

export function trackShowMore(direction: 'earlier' | 'later') {
	const label =
		direction === 'earlier'
			? EVENT_LABEL_FLIGHTS_SHOW_MORE_EARLIER
			: EVENT_LABEL_FLIGHTS_SHOW_MORE_LATER;

	trackEvent({
		category: EVENT_CATEGORY_FLIGHTS,
		action: EVENT_ACTION_FLIGHTS_SHOW_MORE,
		label,
	});
}
