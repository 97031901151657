import {
	EVENT_ACTION_FLIGHTS_QUERY_CHANGE,
	EVENT_CATEGORY_FLIGHTS,
	trackEvent,
} from '../../../shared/analytics';

export function trackQueryChange(query: string) {
	if (!query) {
		return;
	}

	trackEvent({
		category: EVENT_CATEGORY_FLIGHTS,
		action: EVENT_ACTION_FLIGHTS_QUERY_CHANGE,
		label: query,
	});
}
