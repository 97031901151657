import {
	EVENT_ACTION_FLIGHTS_SCOPE_CHANGE,
	EVENT_CATEGORY_FLIGHTS,
	trackEvent,
} from '../../../shared/analytics';
import { FlightListScope } from '../../overview/domain/constants/FlightListScope';
import { getScopeLabelForScope } from './helpers';

export function trackScopeChange(scope: FlightListScope) {
	const scopeLabel = getScopeLabelForScope(scope);

	trackEvent({
		category: EVENT_CATEGORY_FLIGHTS,
		action: EVENT_ACTION_FLIGHTS_SCOPE_CHANGE,
		label: scopeLabel,
	});
}
