import {
	EVENT_ACTION_FLIGHTS_DATE_CHANGE,
	EVENT_CATEGORY_FLIGHTS,
	trackEvent,
} from '../../../shared/analytics';
import { ISelectDay } from '../../search/ui/FlightDateSelect';
import { getDateLabelForDay } from './helpers';

export function trackDateSelectChange(selected: ISelectDay) {
	const dateLabel = getDateLabelForDay(selected.date);

	trackEvent({
		category: EVENT_CATEGORY_FLIGHTS,
		action: EVENT_ACTION_FLIGHTS_DATE_CHANGE,
		label: dateLabel,
	});
}
