import { useEffect } from 'react';
import { useStores } from '../../../../app/root/domain/stores/useStores';
import { IFlightsEnrichmentData } from '../domain/models/IEnrichedFlight';

export function useSetFlightsEnrichmentData({
	airlines,
	airports,
}: IFlightsEnrichmentData) {
	const { airlineStore, airportStore } = useStores();

	useEffect(() => {
		airlineStore.setAirlines(airlines);
		airportStore.setAirports(airports);
	}, []);
}
