import { IDropdownItem } from '../../../../../ui';

export function getValueForItem(item?: IDropdownItem | null): string {
	if (typeof item !== 'string' && item?.type === 'header') {
		return '';
	}

	if (typeof item === 'string') {
		return item;
	}

	if (!item) {
		return '';
	}

	return item.stringValue ?? item.value;
}
