import React, { Component, KeyboardEvent, MouseEvent } from 'react';
import { inject, observer } from 'mobx-react';
import { action, makeObservable } from 'mobx';
import { IDropdownItem } from '../../../../ui';
import styled from '@emotion/styled';
import { AutoSuggest } from '../../../shared/components';
import { II18nProps, withTranslate } from '../../../shared/i18n/translate';
import { SuggestionStore } from '../domain/stores/SuggestionStore';
import { IFlightSearchSuggestion } from '../domain/models/IFlightSearchSuggestion';
import { FlightSearchAutoSuggestItem } from './FlightSearchAutosuggestItem';
import { FlightListStore } from '../../overview/domain/stores';
import { IStores } from '../../../app/root/domain/stores/createStores';
import { getValueForItem } from '../../../shared/components/auto-suggest/helpers/getValueForItem';

interface IProps {
	onQuerySubmit: (query: string) => void;
	onClickSearch?: (event: MouseEvent) => void;
	onSelectItem?: (selectedItem: IFlightSearchSuggestion | null) => void;
	onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
	disabled?: boolean;
}

interface IInjectedProps extends IProps {
	flightSuggestionStore: SuggestionStore<IFlightSearchSuggestion>;
	flightListStore: FlightListStore;
}

const FlightSearchBarWithI18n = inject(
	({ flightSuggestionStore, flightListStore }: IStores) => ({
		flightSuggestionStore,
		flightListStore,
	}),
)(
	observer(
		class FlightSearchBarWithI18n extends Component<IProps & II18nProps> {
			constructor(props: IProps & II18nProps) {
				super(props);

				makeObservable<FlightSearchBarWithI18n, 'onInputValueChange'>(this, {
					onInputValueChange: action,
				});
			}

			protected get injected() {
				return this.props as IInjectedProps & II18nProps;
			}

			protected onInputValueChange = (input: string) => {
				this.injected.flightSuggestionStore.query = input;
			};

			protected onQuerySubmit = () => {
				const { query } = this.injected.flightSuggestionStore;
				const { onQuerySubmit } = this.props;

				onQuerySubmit(query);
			};

			protected onClickSearch = (event: MouseEvent) => {
				const { onClickSearch } = this.props;

				if (onClickSearch) {
					onClickSearch(event);
				}

				this.onQuerySubmit();
			};

			protected onSelectItem = (
				selectedItem: IFlightSearchSuggestion | null,
			) => {
				const { onSelectItem } = this.props;

				if (onSelectItem) {
					onSelectItem(selectedItem);
				}

				this.onQuerySubmit();
			};

			protected onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
				const { onKeyUp } = this.props;

				if (onKeyUp) {
					onKeyUp(event);
				}

				if (event.key === 'Enter') {
					this.onQuerySubmit();
					event.currentTarget.blur();
				}
			};

			protected renderItemDisplayValue = (item: IDropdownItem | null) => {
				if (item === null) {
					return '';
				}

				const value = getValueForItem(item);
				const { query } = this.injected.flightSuggestionStore;

				return <FlightSearchAutoSuggestItem query={query} value={value} />;
			};

			public render() {
				const { t, disabled } = this.props;
				const { suggestions } = this.injected.flightSuggestionStore;
				const { query } = this.injected.flightListStore;
				const itemToString = (item: IFlightSearchSuggestion | null) =>
					item ? item.stringValue : '';

				return (
					<FlightAutoSuggestWrapper>
						<label htmlFor="flight-search-autosuggest">
							{t('search_bar_label')}
						</label>
						<AutoSuggest
							autoSuggestId="flight-search-autosuggest"
							onInputValueChange={this.onInputValueChange}
							placeholder={t('flight_search_placeholder')}
							required
							items={suggestions}
							itemToString={itemToString}
							itemToDisplayValue={this.renderItemDisplayValue}
							onSelectItem={this.onSelectItem}
							onClickSearch={this.onClickSearch}
							initialInputValue={query}
							onKeyUp={this.onKeyUp}
							disabled={disabled}
						/>
					</FlightAutoSuggestWrapper>
				);
			}
		},
	),
);

const FlightAutoSuggestWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export const FlightSearchBar = withTranslate<IProps>(FlightSearchBarWithI18n);
