import {
	EVENT_LABEL_FLIGHTS_SCOPE_ALL,
	EVENT_LABEL_FLIGHTS_SCOPE_ARRIVALS,
	EVENT_LABEL_FLIGHTS_SCOPE_DEPARTURES,
} from '../../../../shared/analytics';
import { FlightListScope } from '../../../overview/domain/constants/FlightListScope';

export function getScopeLabelForScope(scope: FlightListScope) {
	switch (scope) {
		case FlightListScope.All:
			return EVENT_LABEL_FLIGHTS_SCOPE_ALL;

		case FlightListScope.Arrivals:
			return EVENT_LABEL_FLIGHTS_SCOPE_ARRIVALS;

		case FlightListScope.Departures:
			return EVENT_LABEL_FLIGHTS_SCOPE_DEPARTURES;

		default:
			throw new Error('Invalid flight scope');
	}
}
