import React from 'react';
import styled from '@emotion/styled';
import {
	DateSelect,
	IDateSelectItem,
	IDateSelectItemOrDate,
	SelectCurrent,
	StyledSelectMain,
} from '../../../../ui';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { II18nProps, withTranslate } from '../../../shared/i18n/translate';
import { FlightListStore } from '../../overview/domain/stores';
import { IStores } from '../../../app/root/domain/stores/createStores';
import Responsive from '../../../../ui/utils/Responsive';

interface IFlightDateSelect {
	onChange: (e: ISelectDay | null) => void;
	disabled?: boolean;
}

interface IStyledSelectWrap {
	disabled?: boolean;
}

const StyledSelectWrap = styled.div<IStyledSelectWrap>`
	display: flex;
	flex-direction: column;
	gap: 5px;

	${SelectCurrent} {
		${({ disabled }) => disabled && 'visibility: hidden;'}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 14px;

		${StyledSelectMain} {
			padding: 12px;
		}
	}
`;

export interface ISelectDay extends IDateSelectItem {
	date: moment.Moment;
}

interface IInjectedProps extends IFlightDateSelect {
	flightListStore: FlightListStore;
}

const FlightDateSelectWithI18n = inject(({ flightListStore }: IStores) => ({
	flightListStore,
}))(
	observer(
		class FlightDateSelectWithI18n extends React.Component<
			IFlightDateSelect & II18nProps
		> {
			protected get injected() {
				return this.props as IInjectedProps & II18nProps;
			}

			public render() {
				const { t, disabled, onChange } = this.props;
				const today = moment().startOf('day');
				const yesterday = today.clone().subtract(1, 'days');
				const tomorrow = today.clone().add(1, 'day');

				const days: ISelectDay[] = [
					{
						value: yesterday.toDate(),
						hint: t('flight_list_datepicker_yesterday'),
						date: yesterday,
					},
					{
						value: today.toDate(),
						hint: t('flight_list_datepicker_today'),
						date: today,
					},
					{
						value: tomorrow.toDate(),
						hint: t('flight_list_datepicker_tomorrow'),
						date: tomorrow,
					},
				];

				const { selectedDay } = this.injected.flightListStore;
				const index = days.findIndex((day) =>
					day.date.isSame(selectedDay, 'day'),
				);
				const day = index !== -1 ? days[index] : days[1];

				return (
					<StyledSelectWrap disabled={disabled}>
						<label htmlFor="flight-date-select">
							{t('flight_date_select_label')}
						</label>
						<DateSelect
							id="flight-date-select"
							items={days}
							initialSelectedItem={day}
							onChange={onChange as (e: IDateSelectItemOrDate | null) => void}
							disabled={disabled}
						/>
					</StyledSelectWrap>
				);
			}
		},
	),
);

export const FlightDateSelect = withTranslate<IFlightDateSelect>(
	FlightDateSelectWithI18n,
);
