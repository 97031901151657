import { Moment } from 'moment';
import {
	EVENT_ACTION_FLIGHTS_RESULTS_CHANGE,
	EVENT_CATEGORY_FLIGHTS,
	trackEvent,
} from '../../../shared/analytics';
import { FlightListScope } from '../../overview/domain/constants/FlightListScope';
import { getDateLabelForDay, getScopeLabelForScope } from './helpers';

export function trackResultsChange(
	day: Moment,
	scope: FlightListScope,
	query: string,
	totalResults: number,
) {
	const dateLabel = getDateLabelForDay(day);
	const scopeLabel = getScopeLabelForScope(scope);

	let eventLabel = `${dateLabel}/${scopeLabel}`;

	if (query) {
		eventLabel += `/${query}`;
	}

	trackEvent({
		category: EVENT_CATEGORY_FLIGHTS,
		action: EVENT_ACTION_FLIGHTS_RESULTS_CHANGE,
		label: eventLabel,
		value: totalResults,
	});
}
