import {
	EVENT_LABEL_FLIGHTS_DATE_TODAY,
	EVENT_LABEL_FLIGHTS_DATE_TOMORROW,
	EVENT_LABEL_FLIGHTS_DATE_YESTERDAY,
} from '../../../../shared/analytics';
import { moment, Moment } from '../../../../shared/i18n/date-time/moment';

export function getDateLabelForDay(day: Moment) {
	const today = moment().startOf('day');
	const relative = day.clone().startOf('day').diff(today, 'days');

	switch (relative) {
		case -1:
			return EVENT_LABEL_FLIGHTS_DATE_YESTERDAY;

		case 0:
			return EVENT_LABEL_FLIGHTS_DATE_TODAY;

		case 1:
			return EVENT_LABEL_FLIGHTS_DATE_TOMORROW;

		default:
			throw new Error('Invalid date');
	}
}
